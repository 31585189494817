.SOUKIASSIAN-Header {
	&-background {
		background: linear-gradient(
			#483c32,
			#716257
		); //linear-gradient(#525dc5, #9097ee);
	}

	/******* HeaderMenu *******/
	&-menuContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		min-height: 100px;
		width: 100%;
		color: white;

		&-logoTitle {
			display: flex;
			justify-content: space-around;
			align-items: center;
		}
		&-logoLCC {
			max-width: 80px;
			height: 90%;

			+ .SOUKIASSIAN-ImageInfo {
				left: -40px;
			}
		}
		&-companyName {
			margin: 0;
			text-align: center;
		}

		nav {
			display: flex;
			justify-content: space-around;
			align-items: center;
			list-style: none;
			height: 100px;
			padding: 0;
			margin: 0;

			li {
				display: flex;
				height: 100%;
				width: 100%;

				a {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 100%;

					.SOUKIASSIAN-ImageInfo-icon {
						bottom: 50px;
					}

					.SOUKIASSIAN-Header-menuContainer-logoFB,
					.SOUKIASSIAN-Header-menuContainer-logoYT {
						opacity: 0.9;
						padding: 5px;
						width: 40px;
					}
				}

				a,
				a:link,
				a:visited {
					color: white;
					text-decoration: none;
				}

				a:focus,
				a:hover,
				a:active {
					text-decoration: overline;
					font-weight: bold;

					.SOUKIASSIAN-ImageInfo-icon {
						opacity: 0.5;
					}

					.SOUKIASSIAN-Header-menuContainer-logoFB,
					.SOUKIASSIAN-Header-menuContainer-logoYT {
						opacity: 1;
						padding: 3px;
						border: 2px solid #9097ee;
						border-radius: 30px;
					}
					.SOUKIASSIAN-Header-menuContainer-logoYT {
						border-radius: 10px;
					}
				}

				a.active {
					// React className
					font-weight: bold;
				}
			}
		}
	}
	/******* END HeaderMenu *******/

	/******* Header type home *******/
	&-presentation {
		display: flex;
		flex-direction: column;
		align-items: center;
		color: white;
		text-align: center;
		max-width: 500px;

		h1 {
			font-size: 2rem;
			margin: 0;
		}
		p {
			font-size: 2rem;
			margin: 0;
			font-weight: bold;
		}
		&-ButtonCTA {
			margin-top: 100px;
		}

		&-container {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 500px;

			img {
				height: 100%;
				width: auto;
				margin-right: -70px;
			}
		}
	}
	/******* END Header type home *******/

	/******* Header type finalPage *******/
	&-fP {
		&-title {
			color: white;
			text-align: center;
			margin: 0;
			padding: 40px 0 7px 0;
		}
		&-line {
			margin: 0 auto;
			width: 175px;
			height: 6px;
			border-radius: 3px;
			background-color: white;
		}
		&-subTitle {
			color: white;
			font-size: 1.4rem;
			text-align: center;
			margin: 0;
			padding: 7px 0 40px 0;
		}
	}
	/******* END Header type finalPage *******/

	/******* Header type blog *******/
	&-blog {
		padding-bottom: 40px;

		&-title {
			color: white;
			text-align: left;
			margin: 0;
			padding: 40px 0 20px 0;
		}
		&-subTitle {
			color: white;
			font-size: 1.5rem;
			line-height: 1;
			margin: 0 0 10px 0;
			padding: 0 0 7px 0;
		}
		&-description {
			color: white;
			margin: 0;
			font-size: 1.1rem;
		}
		&-image-container {
			display: flex;
			justify-content: space-between;

			img {
				border: 5px solid white;
				width: 350px;
				max-width: 100%;
			}
		}
	}
	/******* END Header type blog *******/

	/******* Header type intermediatePage *******/
	&-iP {
		&-title {
			color: white;
			background-color: #ed8e4a; //#b43522;
			text-align: center;
			margin: 0;
			padding: 20px 0;
		}
		&-imgBackground {
			width: 100%;
			height: 500px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
	}
	/******* END Header type intermediatePage *******/
}

@media only screen and (max-width: 599px) {
	.SOUKIASSIAN-Header {
		&-background {
			padding-left: 10px;
			padding-right: 10px;
		}

		/******* HeaderMenu *******/
		&-menuContainer {
			&-companyName {
				font-size: 1.4rem;
			}

			nav {
				flex-wrap: wrap;
				height: auto;
				line-height: 60px;

				li {
					width: 30%;
					font-size: 1.2rem;
				}
			}
		}
		/******* END HeaderMenu *******/

		/******* Header type blog *******/
		&-blog {
			&-title {
				font-size: 1.7rem;
			}
			&-subTitle {
				font-size: 1.3rem;
			}
			&-description {
				font-size: 1rem;
			}
		}
		/******* END Header type blog *******/
	}
}

@media only screen and (max-width: 699px) {
	.SOUKIASSIAN-Header {
		/******* Header type home *******/
		&-presentation {
			h1 {
				margin-top: 60px;
				font-size: 1.7rem;
			}
			p {
				font-size: 1.7rem;
			}
			&-ButtonCTA {
				margin-top: 60px;
				font-size: 1.3rem;
			}

			&-container {
				display: flex;
				flex-direction: column-reverse;
				height: auto;

				img {
					width: 100%;
					height: auto;
					margin-right: 0;
				}
			}
		}
		/******* END Header type home *******/
	}
}

@media only screen and (min-width: 700px) and (max-width: 767px) {
	.SOUKIASSIAN-Header {
		/******* Header type home *******/
		&-presentation {
			h1 {
				font-size: 1.7rem;
			}
			p {
				font-size: 1.7rem;
			}
			&-ButtonCTA {
				font-size: 0.9rem;
			}

			&-container {
				img {
					margin-right: -60px;
				}
			}
		}
		/******* END Header type home *******/
	}
}

@media only screen and (max-width: 767px) {
	.SOUKIASSIAN-Header {
		/******* Header type blog *******/
		&-blog {
			&-title {
				text-align: center;
			}
			&-subTitle {
				margin-top: 30px;
			}
			&-image-container {
				flex-direction: column;
				width: fit-content;
				margin: 0 auto;
			}
		}
		/******* END Header type blog *******/
	}
}

@media only screen and (min-width: 700px) {
	.SOUKIASSIAN-Header {
		/******* Header type home *******/
		&-presentation {
			position: relative;

			&-titleOverflow {
				overflow: hidden;
			}
			h1,
			p {
				position: relative;
				right: -550px;
				opacity: 0;
				animation: loading_presentation_title 700ms 500ms forwards;
			}
			&-ButtonCTA {
				position: relative;
				top: 200px;
				opacity: 0;
				animation: loading_presentation_button 700ms 1000ms forwards;
			}

			&-container {
				overflow: hidden;
				img {
					position: relative;
					left: -500px;
					opacity: 0;
					animation: loading_presentation_picture 700ms 0ms forwards;
				}
			}
		}
		/******* END Header type home *******/
	}

	@keyframes loading_presentation_picture {
		100% {
			opacity: 1;
			left: 0;
		}
	}
	@keyframes loading_presentation_title {
		40% {
			opacity: 0.3;
		}
		100% {
			opacity: 1;
			right: 0;
		}
	}
	@keyframes loading_presentation_button {
		40% {
			opacity: 0.3;
		}
		100% {
			opacity: 1;
			top: 0;
		}
	}
}

@media only screen and (max-width: 1199px) {
	/******* Header type intermediatePage *******/
	.SOUKIASSIAN-Header {
		&-iP {
			&-imgBackground {
				height: 350px;
			}
		}
	}
	/******* END Header type intermediatePage *******/
}

@media only screen and (min-width: 1921px) {
	/******* Header type intermediatePage *******/
	.SOUKIASSIAN-Header {
		&-iP {
			&-imgBackground {
				height: 700px;
			}
		}
	}
	/******* END Header type intermediatePage *******/
}
