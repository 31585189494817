.SOUKIASSIAN-Success {
	border: 5px solid #6c843a;
	border-radius: 10px;
	padding: 10px;
	margin-top: 10px;
	margin-bottom: 30px;
	font-size: 1.6rem;

	&-delete {
		color: #849e4b;
		margin-right: 10px;
		cursor: pointer;
	}

	&-check {
		color: #849e4b;
		margin-right: 10px;
	}
}
