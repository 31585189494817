.SOUKIASSIAN-BannerThreePicturesLink {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	overflow: hidden;

	.SOUKIASSIAN-PictureTextLink {
		opacity: 0;
		top: 240px;

		&-container {
			margin: 40px 20px;
			&-viewed {
				&:nth-child(1) {
					.SOUKIASSIAN-PictureTextLink {
						animation: loading_three_pictures 0.7s 0s forwards;
					}
				}
				&:nth-child(2) {
					.SOUKIASSIAN-PictureTextLink {
						animation: loading_three_pictures 0.7s 0.3s forwards;
					}
				}
				&:nth-child(3) {
					.SOUKIASSIAN-PictureTextLink {
						animation: loading_three_pictures 0.7s 0.6s forwards;
					}
				}
			}
			&-viewedIsNotAvailable {
				.SOUKIASSIAN-PictureTextLink {
					opacity: 1;
					top: 0;
				}
			}
		}
	}

	&-bordered {
		.SOUKIASSIAN-PictureTextLink {
			border: 6px solid;
			border-radius: 10px;

			&-container {
				&:nth-of-type(1) {
					.SOUKIASSIAN-PictureTextLink {
						border-color: #849e4b;
					}
				}
				&:nth-of-type(2) {
					.SOUKIASSIAN-PictureTextLink {
						border-color: #ffde02;
					}
				}
				&:nth-of-type(3) {
					.SOUKIASSIAN-PictureTextLink {
						border-color: #5ab3c0;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.SOUKIASSIAN-BannerThreePicturesLink {
		.SOUKIASSIAN-PictureTextLink {
			&-container {
				&-viewed {
					&:nth-child(1) {
						.SOUKIASSIAN-PictureTextLink {
							animation: loading_three_pictures 0.7s forwards;
						}
					}
					&:nth-child(2) {
						.SOUKIASSIAN-PictureTextLink {
							animation: loading_three_pictures 0.7s forwards;
						}
					}
					&:nth-child(3) {
						.SOUKIASSIAN-PictureTextLink {
							animation: loading_three_pictures 0.7s forwards;
						}
					}
				}
			}
		}
	}
}

/****** Animation Loading Picture ************/
@keyframes loading_three_pictures {
	100% {
		opacity: 1;
		top: 0;
	}
}
