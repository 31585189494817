.SOUKIASSIAN-BannerText {
	&-title,
	&-text {
		text-align: center;

		&-container {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			min-height: 105px;
		}
	}

	&-title {
		color: black;
		font-weight: bold;
		font-size: 1.6rem;
		width: 100%;

		&-container {
			background-color: white; //#e3e3e3;
		}
	}
	&-text {
		color: white;
		font-size: 1.3rem;
		width: 100%;
		padding: 15px;
		margin: 15px 0;

		&-container {
			background: linear-gradient(
				#483c32,
				#716257
			); //linear-gradient(#525dc5, #9097ee);

			&NoBg {
				background: none;

				.SOUKIASSIAN-BannerText-text {
					padding-bottom: 0;
				}

				p {
					color: black;
				}
			}
		}

		&Bold {
			font-weight: bold;
		}

		span {
			display: block;
		}
	}
}
