.SOUKIASSIAN-AdminClientTable {
	margin: 10px;
	border-collapse: collapse;
	width: calc(100% - 20px);
	text-align: center;

	&-container {
		width: 100%;
		height: calc(100vh - 110px);
		overflow: auto;
		border-bottom: 1px solid black;
	}

	&-showClient {
		margin: 10px auto;
	}

	td,
	th {
		border: 2px solid black;
		padding: 10px;
	}

	th {
		border-bottom: none;
	}
	td {
		word-wrap: anywhere;

		&:nth-of-type(1),
		&:nth-of-type(2) {
			word-wrap: initial;
		}
	}

	&-AddClientRow td {
		border-top: none;
		padding-top: 10px;
		padding-bottom: 10px;

		&:nth-child(3) {
			min-width: 140px;
		}
	}

	button {
		margin: 5px auto;
	}
}

@media only screen and (max-width: 1600px) {
	.SOUKIASSIAN-AdminClientTable {
		font-size: 0.8rem;

		button {
			font-size: 1em;
			min-height: 35px;
			padding: 0 7px;
		}

		input,
		textarea {
			font-size: 1em;
			max-width: 100px;
		}

		input:focus,
		textarea:focus {
			max-width: 150px;
		}
	}
}
