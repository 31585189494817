.SOUKIASSIAN-ImageInfo {
	position: relative;
	z-index: 10;
	height: 100%;

	&-icon {
		cursor: pointer;
		position: absolute;
		top: calc(100% - 35px);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		color: white;
		width: 35px;
		height: 35px;
		border: 2px solid white;
		border-radius: 50%;
		background-color: #525dc5;
		box-shadow: 3px 3px 4px #0000005e;
		opacity: 0;

		&:hover {
			opacity: 1 !important;
		}

		* {
			pointer-events: none;
		}
	}

	&-data {
		position: absolute;
		width: max-content;
		color: white;
		background: linear-gradient(#525dc5, #9097ee);
		padding: 10px;
		border-radius: 5px;
		box-shadow: 8px 8px 10px black;
		text-shadow: none;

		&-title {
			font-size: 1.2em;
			font-weight: bold;
		}
	}
}

.SOUKIASSIAN-ImageInfo-isChangeImage {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;

	.SOUKIASSIAN-ImageInfo-data {
		width: 100%;
	}
}
