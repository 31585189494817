.SOUKIASSIAN-Footer {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	position: relative;
	z-index: 10;
	padding: 10px 30px 30px 80px;
	width: 100%;
	min-height: 200px;
	color: white;
	background-color: #83837b;

	h4 {
		font-size: 1.2rem;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	&-nav {
		li {
			list-style: none;
			line-height: 3rem;
			text-align: left;

			a {
				display: block;
				width: 100%;
				height: 100%;
			}

			a,
			a:link,
			a:visited {
				color: white;
				text-decoration: underline;
			}

			a:focus,
			a:hover,
			a:active {
				font-weight: bold;
				text-decoration: underline;
			}

			a.active {
				font-weight: bold;
			}
		}

		li.SOUKIASSIAN-Footer-noNav {
			line-height: 1.5rem;
			padding-top: 0.75em;
			padding-bottom: 0.75em;
		}
	}

	&-RS {
		a {
			display: flex;
			align-items: center;
			text-decoration: underline;
			color: white;
		}

		&-logoFB,
		&-logoYT {
			opacity: 0.9;
			padding: 5px;
			min-width: 48px;
			max-width: 48px;
		}
		&:hover {
			.SOUKIASSIAN-Footer-RS-logoFB,
			.SOUKIASSIAN-Footer-RS-logoYT {
				opacity: 1;
				padding: 3px;
				border: 2px solid #9097ee;
				border-radius: 30px;
			}
			.SOUKIASSIAN-Footer-RS-logoYT {
				border-radius: 10px;
			}
			a {
				font-weight: bold;
			}
		}
	}
}

@media only screen and (max-width: 450px) {
	.SOUKIASSIAN-Footer {
		padding-left: 20px;

		ul {
			word-break: break-all;
		}
	}
}
@media only screen and (max-width: 767px) {
	.SOUKIASSIAN-Footer {
		justify-content: center;
	}
}
