.SOUKIASSIAN-Stars-icon {
	position: absolute;
	color: gold;
	font-size: 1rem;

	&1 {
		top: 4%;
		left: 7%;
	}
	&2 {
		top: 10%;
		left: 12%;
	}
	&3 {
		top: 19%;
		left: 10%;
	}
	&4 {
		top: 19%;
		left: 4%;
	}
	&5 {
		top: 10%;
		left: 2%;
	}
}
