.SOUKIASSIAN-ImageTextContainer {
	margin: 0 auto;

	&-child {
		display: flex;
		justify-content: space-between;
		border: 3px solid #83837b;
		min-height: 250px;

		&:nth-child(odd) {
			border-right: none;
		}
		&:nth-child(even) {
			border-left: none;
			border-top: none;
			border-bottom: none;
		}
		&:last-child {
			border-bottom: 3px solid #83837b;
		}

		&NoImage {
			&:nth-child(odd) {
				border-right: 3px solid #83837b;
			}
			&:nth-child(even) {
				border-left: 3px solid #83837b;
			}

			h2 {
				text-align: center;
			}
		}

		&NoMinHeight {
			min-height: unset;
		}

		&-text {
			flex: 1 1 0;
			display: flex;
			flex-direction: column;
			align-self: center;
			padding: 10px;

			h2 {
				margin-top: 0;
				font-size: 1.3rem;
			}

			> span {
				font-size: 1.1rem;
				display: block;
				margin: 8px 0;
			}

			button {
				margin: 0 auto;
			}

			&AlignCenter {
				text-align: center;
			}
		}
		&-imgBackground {
			flex: 1 1 0;
			display: flex;
			padding: 10px 10px 10px 13px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;

			&:hover {
				.SOUKIASSIAN-ImageInfo-icon {
					opacity: 0.5;
				}
			}

			&Flipped {
				transform: scaleX(-1);
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.SOUKIASSIAN-ImageTextContainer {
		&-child:not(.SOUKIASSIAN-ImageTextContainer-childNoImage) {
			flex-direction: column;

			&:nth-child(even) {
				flex-direction: column-reverse;
			}
		}
	}

	.SOUKIASSIAN-ImageTextContainer {
		margin: 0 auto;

		&-child {
			&-text {
				span {
					font-size: 1.2rem;
				}
			}
			&-imgBackground {
				min-height: 400px;
			}
		}
	}
}
