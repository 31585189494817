.SOUKIASSIAN-BlogEtiquette {
	display: block;
	text-decoration: none;
	padding: 0 20px 40px 20px;

	&-container {
		margin: 40px 20px;
	}
	&-background {
		background: #716257;
		border: 5px solid white;
		border-radius: 40px;
	}

	&-title {
		color: white;
		text-align: left;
		margin: 0;
		padding: 40px 0 20px 0;
	}
	&-subTitle {
		color: white;
		font-size: 1.5rem;
		line-height: 1;
		margin: 0 0 10px 0;
		padding: 0 0 7px 0;
	}
	&-description {
		color: white;
		margin: 0;
		font-size: 1.1rem;
	}
	&-image-container {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		img {
			width: 250px;
			max-width: 100%;
			margin: 0 auto;
		}
	}
}

@media only screen and (max-width: 599px) {
	.SOUKIASSIAN-BlogEtiquette {
		&-title {
			font-size: 1.7rem;
		}
		&-subTitle {
			font-size: 1.3rem;
		}
		&-description {
			font-size: 1rem;
		}
	}
}

@media only screen and (max-width: 767px) {
	.SOUKIASSIAN-BlogEtiquette {
		&-title {
			text-align: center;
		}
		&-subTitle {
			margin-top: 30px;
		}
		&-image-container {
			flex-direction: column;
			width: fit-content;
			margin: 0 auto;
		}
	}
}
