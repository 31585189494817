.SOUKIASSIAN-ClientInfo {
	margin-top: 25px;
	margin-bottom: 25px;

	> * {
		margin: 15px auto;
	}

	&-row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;

		&-info {
			border: 2px solid #849e4b;
			border-radius: 5px;
			padding: 5px;
			margin: 0 auto;

			&ColorPro {
				border-color: #5ab3c0;
			}

			&-title {
				font-weight: bold;
				font-size: 1.1em;
			}
			&-data {
				margin-left: 10px;
				&-void {
					font-style: italic;
				}
				&-required {
					color: #ae6633; //#9d1e2a;
					margin-top: 5px;
					margin-left: -10px;
					font-style: italic;
					font-weight: bold;
				}
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.SOUKIASSIAN-ClientInfo {
		&-row {
			&-info {
				&:first-child {
					margin-bottom: 15px;
				}
			}
		}
	}
}
