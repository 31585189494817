@font-face {
	font-family: 'Lato';
	font-weight: 300;
	src: url('./assets/fonts/Lato/Lato-Light.ttf') format('truetype');
}
@font-face {
	font-family: 'Lato';
	font-weight: 400;
	src: url('./assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
}
@font-face {
	font-family: 'Lato';
	font-weight: 700;
	src: url('./assets/fonts/Lato/Lato-Bold.ttf') format('truetype');
}

* {
	box-sizing: border-box;
}

html {
	height: 100%;
}

body {
	width: 100vw;
	min-height: 100%;
	margin: 0;
	overflow-x: hidden;
	font-weight: normal;
	font-family: 'Lato', sans-serif;
	background-color: #e0cda9; //#cccffb;
	//background-color: #fff7f7;
}

hr {
	width: 100%;
	color: #716257;
}

.hide {
	display: none;
}
.center {
	margin: 0 auto;
}
.margined-x {
	margin-left: 20px;
	margin-right: 20px;
}
.margined-y {
	margin-top: 20px;
	margin-bottom: 20px;
}
.underline {
	text-decoration: underline;
}
.textCenter {
	text-align: center;
}
.noBreak {
	white-space: nowrap;
}
.align {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

/* Tout appareil */
.col-max-blocked {
	max-width: 730px;
}
.col-xl-max-blocked {
	max-width: 1200px;
}
.col-1 {
	width: 8.33%;
}
.col-2 {
	width: 16.66%;
}
.col-3 {
	width: 25%;
}
.col-4 {
	width: 33.33%;
}
.col-5 {
	width: 41.66%;
}
.col-6 {
	width: 50%;
}
.col-7 {
	width: 58.33%;
}
.col-8 {
	width: 66.66%;
}
.col-9 {
	width: 75%;
}
.col-10 {
	width: 83.33%;
}
.col-11 {
	width: 91.66%;
}
.col-12 {
	width: 100%;
}

/* Très Petit appareil */
@media only screen and (max-width: 599px) {
	.col-xxs-1 {
		width: 8.33%;
	}
	.col-xxs-2 {
		width: 16.66%;
	}
	.col-xxs-3 {
		width: 25%;
	}
	.col-xxs-4 {
		width: 33.33%;
	}
	.col-xxs-5 {
		width: 41.66%;
	}
	.col-xxs-6 {
		width: 50%;
	}
	.col-xxs-7 {
		width: 58.33%;
	}
	.col-xxs-8 {
		width: 66.66%;
	}
	.col-xxs-9 {
		width: 75%;
	}
	.col-xxs-10 {
		width: 83.33%;
	}
	.col-xxs-11 {
		width: 91.66%;
	}
	.col-xxs-12 {
		width: 100%;
	}
}

/* Petit appareil */
@media only screen and (min-width: 600px) and (max-width: 767px) {
	.col-xs-1 {
		width: 8.33%;
	}
	.col-xs-2 {
		width: 16.66%;
	}
	.col-xs-3 {
		width: 25%;
	}
	.col-xs-4 {
		width: 33.33%;
	}
	.col-xs-5 {
		width: 41.66%;
	}
	.col-xs-6 {
		width: 50%;
	}
	.col-xs-7 {
		width: 58.33%;
	}
	.col-xs-8 {
		width: 66.66%;
	}
	.col-xs-9 {
		width: 75%;
	}
	.col-xs-10 {
		width: 83.33%;
	}
	.col-xs-11 {
		width: 91.66%;
	}
	.col-xs-12 {
		width: 100%;
	}
}
/* Moyen appareil */
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.col-s-1 {
		width: 8.33%;
	}
	.col-s-2 {
		width: 16.66%;
	}
	.col-s-3 {
		width: 25%;
	}
	.col-s-4 {
		width: 33.33%;
	}
	.col-s-5 {
		width: 41.66%;
	}
	.col-s-6 {
		width: 50%;
	}
	.col-s-7 {
		width: 58.33%;
	}
	.col-s-8 {
		width: 66.66%;
	}
	.col-s-9 {
		width: 75%;
	}
	.col-s-10 {
		width: 83.33%;
	}
	.col-s-11 {
		width: 91.66%;
	}
	.col-s-12 {
		width: 100%;
	}
}

/* Grand appareil */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.col-x-1 {
		width: 8.33%;
	}
	.col-x-2 {
		width: 16.66%;
	}
	.col-x-3 {
		width: 25%;
	}
	.col-x-4 {
		width: 33.33%;
	}
	.col-x-5 {
		width: 41.66%;
	}
	.col-x-6 {
		width: 50%;
	}
	.col-x-7 {
		width: 58.33%;
	}
	.col-x-8 {
		width: 66.66%;
	}
	.col-x-9 {
		width: 75%;
	}
	.col-x-10 {
		width: 83.33%;
	}
	.col-x-11 {
		width: 91.66%;
	}
	.col-x-12 {
		width: 100%;
	}
}

/*Classique appareil : width >= 1200 && width <= 1920*/

/* Très Grand appareil */
@media only screen and (min-width: 1921px) {
	.col-xx-1 {
		width: 8.33%;
	}
	.col-xx-2 {
		width: 16.66%;
	}
	.col-xx-3 {
		width: 25%;
	}
	.col-xx-4 {
		width: 33.33%;
	}
	.col-xx-5 {
		width: 41.66%;
	}
	.col-xx-6 {
		width: 50%;
	}
	.col-xx-7 {
		width: 58.33%;
	}
	.col-xx-8 {
		width: 66.66%;
	}
	.col-xx-9 {
		width: 75%;
	}
	.col-xx-10 {
		width: 83.33%;
	}
	.col-xx-11 {
		width: 91.66%;
	}
	.col-xx-12 {
		width: 100%;
	}
}
