.SOUKIASSIAN-Input {
	p {
		margin-bottom: 0;
	}

	input,
	textarea {
		border: none;
		background: none;
		outline: none;
		border-bottom: 1px solid black;
		margin: 0 5px 15px 5px;
		height: 50px;
		min-width: 250px;
		font-size: 1rem;

		&:focus {
			border-color: #716257; //#849e4b;
			border-width: 3px;
		}
	}

	input[type='checkbox'] {
		height: 20px;
		width: 20px;
		min-width: unset;
	}

	textarea {
		padding-top: 10px;
		height: 150px;
	}

	&-admin {
		input,
		textarea {
			margin: 7px 2px;
			padding: 3px;
			height: unset;
			min-width: auto;
		}
	}

	&-colorPro {
		input,
		textarea {
			&:focus {
				border-color: #5ab3c0;
			}
		}
	}

	&-showPWD {
		display: flex;
		margin: 0 5px 15px 5px;
		height: 20px;
		cursor: pointer;

		input {
			margin: 0 0 0 5px;
		}
	}

	input:read-only {
		cursor: default;

		&:focus {
			border-color: black;
		}
	}
}

.SOUKIASSIAN-Input[class*='col-'] {
	> input,
	> textarea {
		width: calc(100% - 10px);
	}
}
