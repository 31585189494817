.SOUKIASSIAN-Contact {
	.SOUKIASSIAN-HarmoniousTitle {
		margin: 0 auto 30px auto;
	}

	&-form {
		> * {
			margin-left: auto;
			margin-right: auto;
		}

		&-row {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		&-condition {
			line-height: 40px;
		}
	}
}
