@media only screen and (min-width: 600px) and (max-width: 1199px) {
	.SOUKIASSIAN-Workshops {
		.SOUKIASSIAN-BannerImageText {
			background-position-y: -75px;
		}
	}
}
@media only screen and (min-width: 1200px) {
	.SOUKIASSIAN-Workshops {
		.SOUKIASSIAN-BannerImageText {
			background-position-y: -150px;
		}
	}
}
