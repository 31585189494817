.SOUKIASSIAN-Login {
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	width: 300px;
	height: 400px;
	top: calc(50% - 200px);
	left: calc(50% - 150px);
	padding: 20px;
	border: 3px solid #9097ee;

	&-title {
		font-size: 2rem;
		font-weight: bold;

		&-icon {
			margin-right: 5px;
		}
	}
}
