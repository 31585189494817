.SOUKIASSIAN-BubblePrice {
	&-container {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: -30px;
		right: -30px;
		z-index: 10;
		color: white;
		font-size: 20px;
		font-weight: bold;
		width: 70px;
		height: 70px;
		background-color: #ae6633;
		border: 2px dashed #483c32;
		box-shadow: -3px 5px 5px rgba(0, 0, 0, 0.4);
		border-radius: 50px;
	}
}
