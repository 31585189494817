.SOUKIASSIAN-Blog {
	&-BannerText {
		.SOUKIASSIAN-BannerText-title {
			text-align: left;
		}
	}

	&-section {
		margin-top: 40px !important;
		margin-bottom: 40px !important;
		font-size: 1.3rem;

		h2 {
			font-size: 1.4rem;
		}
		ul {
			padding-left: 20px;
		}
	}
}

@media only screen and (max-width: 767px) {
	.SOUKIASSIAN-Blog {
		.SOUKIASSIAN-Button {
			margin-left: 10px;
		}

		&-BannerText .SOUKIASSIAN-BannerText-title,
		&-section {
			padding-left: 10px;
			padding-right: 10px;
		}

		&-BannerText {
			.SOUKIASSIAN-BannerText-title {
				font-size: 1.5rem;
			}
		}

		&-section {
			font-size: 1.2rem;

			h2 {
				font-size: 1.3rem;
			}
		}
	}
}
