.SOUKIASSIAN-PictureTextLink {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 15px;
	transition: 0.2s transform ease-in-out;
	margin-bottom: 70px !important;

	.SOUKIASSIAN-ImageInfo {
		position: absolute;
		top: 0;
	}

	&:hover {
		.SOUKIASSIAN-ImageInfo-icon {
			opacity: 0.5;
		}
	}

	&-container {
		width: 345px;
		height: 235px;

		&-animate {
			.SOUKIASSIAN-PictureTextLink {
				opacity: 0;
				top: 240px;
			}

			&.SOUKIASSIAN-PictureTextLink-container-viewed {
				.SOUKIASSIAN-PictureTextLink {
					animation: loading_picture 700ms 0ms forwards;
				}
			}
			&.SOUKIASSIAN-PictureTextLink-container-viewedIsNotAvailable {
				.SOUKIASSIAN-PictureTextLink {
					opacity: 1;
					top: 0;
				}
			}
		}
	}

	a {
		display: block;
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 100%;

		img {
			position: absolute;
			height: 110%;
			top: -6px;
			left: -6px;
			text-align: center;
		}

		h3 {
			position: absolute;
			bottom: 0;
			left: 0;
			color: white;
			font-size: 1.65rem;
			font-weight: bold;
			text-align: center;
			width: 100%;
			z-index: 1;
			text-shadow: 2px 2px 4px black;
		}
	}
}

.SOUKIASSIAN-PictureTextLink-bordered {
	border: 6px solid;
	border-image-slice: 1;
	border-image-source: linear-gradient(
		90deg,
		#849e4b,
		#ffde02 15% 85%,
		#5ab3c0
	);
}

.SOUKIASSIAN-PictureTextLink {
	&:hover,
	&:focus {
		transform: scale(1.1, 1.1);
	}
}

@media only screen and (min-width: 1921px) {
	.SOUKIASSIAN-PictureTextLink-container {
		width: 550px;
		height: 370px;

		a {
			h3 {
				font-size: 2rem;
			}
		}
	}
}

/****** Animation Loading Picture ************/
@keyframes loading_picture {
	100% {
		opacity: 1;
		top: 0;
	}
}
