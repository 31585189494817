.SOUKIASSIAN-Error {
	border: 1px solid #871301;
	border-radius: 5px;
	padding: 2px;
	margin-top: 10px;
	margin-bottom: 30px;

	&-delete {
		color: #ed8e4a; //#b43522;
		margin-right: 10px;
		cursor: pointer;
	}

	&-exclamation {
		color: #ed8e4a; //#b43522;
	}
}
