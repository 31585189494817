.SOUKIASSIAN-ImageTextFilletPowder {
	margin: 50px auto;

	&-child {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 300px;

		&:nth-child(odd) {
			.SOUKIASSIAN-ImageTextFilletPowder-child-filled {
				&::before {
					border-image-slice: 1;
					border-image-source: linear-gradient(
						to left,
						#5ab3c0,
						#b43522
					);
				}
			}
		}
		&:nth-child(even) {
			.SOUKIASSIAN-ImageTextFilletPowder-child-filled {
				&::before {
					border-image-slice: 1;
					border-image-source: linear-gradient(
						to right,
						#ffde02,
						#849e4b
					);
				}
			}
		}

		&-PictureTextLink {
			width: 315px !important;
			height: 200px !important;
			z-index: 1;

			a {
				display: block;
				position: relative;
				overflow: hidden;
				width: 100%;
				height: 100%;
				border-radius: 15px;
			}
		}

		&-text {
			font-size: 1.2rem;
			width: 315px;
		}

		&-filled {
			position: relative;
			width: 100px;
			height: 100px;
			transform: scaleY(3.12);

			&-container {
				display: flex;
				align-items: center;
				min-width: 100px;
				max-width: 100px;
				height: 100%;
			}

			&::before {
				content: '';
				position: absolute;
				display: block;
				top: 15px;
				left: 15px;
				border-left: 2px solid;
				border-top: 2px solid;
				width: 68px;
				height: 68px;
				transform: rotate(-45deg);
			}
		}

		&Right {
			.SOUKIASSIAN-ImageTextFilletPowder-child-filled {
				&::before {
					transform: rotate(135deg);
				}
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.SOUKIASSIAN-ImageTextFilletPowder {
		&-child {
			flex-direction: column;
			height: auto;
			border: 3px solid #ae6633; //#b43522;
			margin-bottom: 50px;
			padding-bottom: 15px;

			&-filled-container {
				transform: rotate(-90deg);
			}

			&-PictureTextLink {
				margin-top: 50px;
			}

			&-text {
				margin-top: 40px;
				font-size: 1.7rem;
				max-width: 520px;
				text-align: center;
			}

			&Right {
				flex-direction: column-reverse;

				.SOUKIASSIAN-ImageTextFilletPowder-child-filled-container {
					transform: rotate(90deg);
				}
			}
		}
	}
}
