.SOUKIASSIAN-BannerTestimonie {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 auto;
	padding: 30px 20px;

	&-container {
		background-color: white;
	}

	&-imgTitle {
		display: flex;
		flex-direction: column;
		align-items: center;
		min-width: 200px;

		&:hover {
			.SOUKIASSIAN-ImageInfo-icon {
				opacity: 0.5;
			}
		}

		&-imgContainer {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 150px;
			height: 150px;
			border-radius: 75px;
			overflow: hidden;

			img {
				width: 160px;
			}
		}

		h3 {
			color: #83837b;
			font-size: 1.3rem;
			text-align: center;
			margin: 20px 0;
		}
	}

	&-quoteLeft {
		position: absolute;
		color: #83837b;
		font-size: 2rem;
		top: -1.7rem;
		left: -1.8rem;
	}
	p {
		position: relative;
		margin: 1.5rem 50px;
		font-size: 1.3rem;
		text-align: center;
	}
	&-quoteRight {
		position: absolute;
		color: #83837b;
		font-size: 2rem;
		bottom: -1.7rem;
		right: -1.8rem;
	}
}

@media only screen and (max-width: 767px) {
	.SOUKIASSIAN-BannerTestimonie {
		flex-direction: column;
		padding-left: 0;
		padding-right: 0;

		p {
			font-size: 1.5rem;
		}
	}
}
