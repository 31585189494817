.SOUKIASSIAN-HarmoniousTitle {
	padding: 0 10px;

	&-sticky {
		h2 {
			position: sticky;
			top: 20px;
			z-index: 2;
			background-color: #e0cda9;
		}
	}

	> h2 {
		text-align: center;
		font-weight: normal;
		font-size: 2rem;
		max-width: max-content;
		margin-left: auto;
		margin-right: auto;
		border-bottom: 3px solid;
		border-image-slice: 1;
		border-image-source: linear-gradient(
			to right,
			#5ab3c0,
			#b43522,
			#ffde02,
			#849e4b
		);

		span {
			font-size: 0.8em;
			font-weight: bold;
		}
	}
}
