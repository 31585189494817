.SOUKIASSIAN-Stages {
	.SOUKIASSIAN-ImageTextFilletPowder-child {
		position: relative;
		background-color: #e0cda9;

		&:nth-child(1) {
			z-index: 1;
			.SOUKIASSIAN-PictureTextLink a h3 {
				font-size: 1.5rem;
			}
		}
		&:nth-child(2) {
			z-index: 2;
		}
		&:nth-child(3) {
			z-index: 3;
		}
		&:nth-child(4) {
			z-index: 4;
			.SOUKIASSIAN-PictureTextLink a h3 {
				font-size: 1.5rem;
			}
		}
		&:nth-child(5) {
			z-index: 5;
		}
	}
}
