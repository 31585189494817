.SOUKIASSIAN-Home {
	&-headerSubTitle {
		.SOUKIASSIAN-BannerText-title {
			span {
				color: black;
				font-size: 1.5rem;
				margin-right: 30px;

				&:last-of-type {
					margin-right: 0;
				}
			}
		}
		.SOUKIASSIAN-BannerText-text {
			font-size: 1.5rem;
			max-width: 850px;
		}
	}
	&-ButtonCTA {
		margin: 50px auto;

		&-stage {
			transform: scale(0);

			a {
				font-size: 1.7rem;
				padding-top: 30px;
				padding-bottom: 30px;
			}

			&-viewed {
				animation: loading_button_scaleUp 700ms 0ms forwards;
			}
			&-viewedIsNotAvailable {
				transform: scale(1);
			}
		}
	}
	&-HarmoniousTitle {
		margin-top: 80px;
	}
	&-PictureTextLink {
		margin: 50px auto 30px auto;
	}
}

@media only screen and (max-width: 767px) {
	.SOUKIASSIAN-Home-PictureTextLink {
		max-width: 83%;
	}
}

/****** Animation Loading Button ScaleUp ************/
@keyframes loading_button_scaleUp {
	100% {
		transform: scale(1);
	}
}
