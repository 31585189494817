.SOUKIASSIAN-ClientAccount {
	&-content {
		padding: 0 10px;
	}

	.SOUKIASSIAN-LoginClient {
		margin-top: 20px;
		margin-bottom: 20px;
	}
}
