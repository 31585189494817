.SOUKIASSIAN-Select {
	width: 100%;
	max-height: 140px;
	overflow-y: auto;
	border: 2px solid #9097ee;
	border-radius: 5px;
	padding: 0 5px;

	&-title {
		margin-bottom: 5px;
	}

	&-option {
		margin: 3px 0;
		border-bottom: 2px dotted #9097ee;
		cursor: pointer;

		&:hover {
			color: white;
			background-color: #9097ee;
			opacity: 0.7;
		}
		&-selected {
			color: white;
			background-color: #9097ee;

			&:hover {
				opacity: 1;
			}
		}
	}
}
