.SOUKIASSIAN-BannerImageText {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 500px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	&:hover {
		.SOUKIASSIAN-ImageInfo-icon {
			opacity: 0.5;
		}
	}

	&:not(.SOUKIASSIAN-BannerImageTextNoText):before {
		position: absolute;
		content: '';
		z-index: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color: #000000, $alpha: 0.4);
	}

	&Flipped {
		transform: scaleX(-1);
	}

	p {
		z-index: 1;
		color: white;
		font-size: 2rem;
		font-weight: bold;
		text-align: center;
		margin: 0;
		padding: 30px 20px;

		span {
			line-height: 2em;
		}
	}
}

@media only screen and (max-width: 1199px) {
	.SOUKIASSIAN-BannerImageText {
		min-height: 350px;
	}
}

@media only screen and (min-width: 1921px) {
	.SOUKIASSIAN-BannerImageText {
		min-height: 700px;
	}
}
