.SOUKIASSIAN-Img {
	&:hover {
		+ .SOUKIASSIAN-ImageInfo {
			.SOUKIASSIAN-ImageInfo-icon {
				opacity: 0.5;
			}
		}
	}

	&Flipped {
		transform: scaleX(-1);
	}
}
