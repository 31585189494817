.SOUKIASSIAN-LoginClient {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	margin: 0 auto;
	max-width: 360px;
	min-height: 550px;
	padding: 20px;
	border: 3px solid #9097ee;

	&-title {
		font-size: 2rem;
		font-weight: bold;

		&-iconBack,
		&-iconUser {
			margin-right: 5px;
		}
		&-iconBack {
			cursor: pointer;
			color: #ae6633; //#9d1e2a;
		}
	}
}
