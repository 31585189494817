.SOUKIASSIAN-RegistrationList {
	&-backButton {
		padding-left: 10px;
	}

	&-Category {
		&-pictureList {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			gap: 20px;
			overflow: hidden;

			.SOUKIASSIAN-PictureTextLink {
				max-width: 370px;
				margin-left: auto;
				margin-right: auto;
			}

			&-reverse {
				flex-wrap: wrap-reverse;
			}
		}

		.SOUKIASSIAN-HarmoniousTitle {
			&:nth-child(1),
			&:nth-child(3) {
				.SOUKIASSIAN-PictureTextLink {
					opacity: 0;
					right: 240px;

					&-container {
						&-viewed {
							.SOUKIASSIAN-PictureTextLink {
								animation: loading_picture_from_left 0.7s
									forwards;
							}
						}
						&-viewedIsNotAvailable {
							.SOUKIASSIAN-PictureTextLink {
								opacity: 1;
								right: 0;
							}
						}
					}
				}
			}
			&:nth-child(2) {
				.SOUKIASSIAN-PictureTextLink {
					opacity: 0;
					top: 240px;

					&-container {
						&-viewed {
							.SOUKIASSIAN-PictureTextLink {
								animation: loading_picture_from_bottom 0.7s
									forwards;
							}
						}
						&-viewedIsNotAvailable {
							.SOUKIASSIAN-PictureTextLink {
								opacity: 1;
								top: 0;
							}
						}
					}
				}
			}
			&:nth-child(4) {
				.SOUKIASSIAN-PictureTextLink {
					opacity: 0;
					left: 240px;

					&-container {
						&-viewed {
							.SOUKIASSIAN-PictureTextLink {
								animation: loading_picture_from_right 0.7s
									forwards;
							}
						}
						&-viewedIsNotAvailable {
							.SOUKIASSIAN-PictureTextLink {
								opacity: 1;
								left: 0;
							}
						}
					}
				}
			}
		}
	}

	&-Form {
		&-buttonSubscribe,
		.SOUKIASSIAN-Error {
			margin-top: 15px !important;
		}
		.SOUKIASSIAN-Success {
			margin-top: 15px;
		}
	}
}

.SOUKIASSIAN-RegistrationList,
.SOUKIASSIAN-RegistrationList-Category {
	.SOUKIASSIAN-HarmoniousTitle {
		margin: 0 auto 30px auto;
	}
}

@media only screen and (min-width: 1921px) {
	.SOUKIASSIAN-RegistrationList-Category-pictureList {
		.SOUKIASSIAN-PictureTextLink {
			max-width: 550px;
		}
	}
}

/****** Animation Loading Picture ************/
@keyframes loading_picture_from_bottom {
	100% {
		opacity: 1;
		top: 0;
	}
}
@keyframes loading_picture_from_left {
	100% {
		opacity: 1;
		right: 0;
	}
}
@keyframes loading_picture_from_right {
	100% {
		opacity: 1;
		left: 0;
	}
}
