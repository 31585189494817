.SOUKIASSIAN-Button {
	display: block;
	position: relative;
	top: 0;
	left: 0;
	transition:
		top 0.2s ease-in-out,
		left 0.2s ease-in-out;
	min-height: 50px;
	padding: 0;
	border: none;
	border-radius: 10px;
	background-color: #ae6633; //#9d1e2a;
	color: white;
	font-weight: bold;
	font-size: 1rem;

	&-NotLink {
		padding: 0 10px;
		cursor: pointer;
	}

	&:not(:disabled):hover {
		background-color: #ed8e4a; //#b43522;
		box-shadow: 5px 5px 9px -4px black;
		position: relative;
		top: -5px;
		left: -5px;
	}

	&:disabled {
		cursor: wait;
		background-color: #83837b;
	}

	a {
		display: inline-block;
		line-height: 1.5em;
		width: 100%;
		height: 100%;
		margin: auto 0;
		padding: 16px 15px;
		color: white;
		text-decoration: none;
	}
}
