.SOUKIASSIAN-Trainings {
	.SOUKIASSIAN-ImageTextFilletPowder-child {
		position: relative;
		background-color: #e0cda9;

		&:nth-child(1) {
			z-index: 1;
		}
		&:nth-child(2) {
			z-index: 2;
		}
		&:nth-child(3) {
			z-index: 3;
		}
		&:nth-child(4) {
			z-index: 4;
		}
	}
}
