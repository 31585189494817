.SOUKIASSIAN-Registration {
	&-form {
		> * {
			margin: 0 auto;
		}

		&-row {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
		}

		&-condition {
			margin-left: 30px;
			line-height: 50px;
		}
	}
}
