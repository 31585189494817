.SOUKIASSIAN-Collapse {
	overflow: hidden;
	transition: height 0.3s;

	&-title {
		display: flex;
		justify-content: space-between;
		cursor: pointer;
		margin: 0;
		padding: 20px 0;

		.SOUKIASSIAN-Collapse-title-icon {
			transition: transform 0.3s;
			padding: 0 5px;
		}

		&-opened {
			.SOUKIASSIAN-Collapse-title-icon {
				transform: rotate(-180deg);
			}
		}
	}
}
