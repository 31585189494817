.SOUKIASSIAN-PercentTextContainer {
	margin: 50px auto;
	text-align: center;

	> p {
		font-size: 1.2rem;
	}

	&-line {
		margin: 50px auto;
		width: 175px;
		height: 6px;
		border-radius: 3px;
		background-color: #716257;
	}

	&-child {
		display: flex;
		align-items: center;
		max-width: 500px;
		margin: 0 auto;
		padding: 20px 20px 20px 0;

		&-percent {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 120px;
			min-width: 120px;
			max-width: 120px;
			height: 120px;
			min-height: 120px;
			max-height: 120px;
			font-size: 1.2rem;
			font-weight: bold;
			margin: 0 40px;
			border: 25px solid #716257;
			border-radius: 50%;
		}

		p {
			font-size: 1.1rem;
			text-align: left;
			font-weight: bold;
		}
	}
}
