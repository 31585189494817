.SOUKIASSIAN-ClientForm {
	> * {
		margin: 0 auto;
	}

	&-row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}
}
