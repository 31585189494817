.SOUKIASSIAN-AdminImageTable {
	height: calc(100vh - 110px);
	overflow-y: auto;

	&-icon {
		cursor: pointer;
		color: grey;
		font-size: 100px;
	}

	&-folder {
		display: flex;
		flex-direction: column;
		text-align: center;
		flex: 0 1 0;

		&-container {
			display: flex;
			flex-wrap: wrap;
			gap: 30px;
		}
	}

	&-ImageHandling,
	&-AddImageRow {
		display: flex;
		width: 100%;
		margin-top: 30px;
		border-top: 5px solid #ed8e4a; //#b43522;
		border-bottom: 5px solid #ed8e4a; //#b43522;

		&-image {
			flex: 1 1 0;
			display: flex;
			flex-direction: column;
			align-items: center;

			input {
				cursor: pointer;
			}

			img {
				max-width: 100%;
				max-height: 600px;
				margin: auto;
			}
		}

		&-data {
			flex: 1 1 0;
			font-size: 2rem;

			div {
				display: flex;

				> span {
					color: grey;
					min-width: 400px;

					span.small {
						font-size: 1rem;
					}
				}
			}

			.SOUKIASSIAN-Button {
				margin: 10px 0;
			}
		}
	}

	&-ImageRow {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 10px 20px;

		&-container {
			display: flex;
			flex-direction: row-reverse;
			flex-wrap: wrap-reverse;
			justify-content: space-around;
			align-items: center;
		}

		&-image {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 200px;
			height: 200px;

			img {
				cursor: pointer;
				max-width: 100%;
				max-height: 100%;
			}
		}

		&-title {
			cursor: text;
			&-trash {
				cursor: pointer;
				color: #ed8e4a; //#b43522;
				margin-left: 7px;
			}
		}
	}
}
