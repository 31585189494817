.SOUKIASSIAN-ClientNewPassword {
	> * {
		margin: 0 auto;
	}

	&-row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}

	.SOUKIASSIAN-Success {
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.SOUKIASSIAN-Button {
		margin-top: 10px;
	}
}
